.auth-form {
  label {
    @apply .block .text-sm .font-bold .mb-2;
  }
  .error-text {
    @apply  .text-xs .font-bold .italic .mt-1;

  }
  .input-text {
    @apply .shadow .appearance-none .border .rounded .w-full .py-2 .px-3 .text-gray-700 .leading-tight;
    &:focus {
      @apply .outline-none .shadow-outline;
    }
  }
  .primary-btn {
    @apply .font-bold .py-2 .rounded-full;

    @screen lg {
      @apply .mr-4;

    }
  }
  .secondary-btn {
    @apply  .font-semibold .py-2 .px-4 .rounded-full .mt-2;

    @screen lg{
      @apply mt-0;
    }
  }
}