@tailwind base;
@tailwind components;
@tailwind utilities;
.auth-form label {
  @apply .block .text-sm .font-bold .mb-2;
}
.auth-form .error-text {
  @apply .text-xs .font-bold .italic .mt-1;
}
.auth-form .input-text {
  @apply .shadow .appearance-none .border .rounded .w-full .py-2 .px-3 .text-gray-700 .leading-tight;
}
.auth-form .input-text:focus {
  @apply .outline-none .shadow-outline;
}
.auth-form .primary-btn {
  @apply .font-bold .py-2 .rounded-full;
}
@screen lg {
  .auth-form .primary-btn {
    @apply .mr-4;
  }
}
.auth-form .secondary-btn {
  @apply .font-semibold .py-2 .px-4 .rounded-full .mt-2;
}
@screen lg {
  .auth-form .secondary-btn {
    @apply mt-0;
  }
}

html {
  height: 100%;
}

.test {
  background-color: red;
}

.main-content {
  width: 51.666667%;
}

.login-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
}

.image-logo {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.form-end {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.login-btn {
  font-weight: 600;
  background-color: white;
  padding: 2px 10px;
  border-radius: 10px;
}

.forget-btn {
  margin-left: 5px;
  font-weight: 600;
  background-color: white;
  padding: 2px 10px;
  border-radius: 10px;
}

.ludus-auth {
  display: flex;
  justify-content: center;
}

body {
  min-height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,399ae5+26,7db9e8+38,4ea3e5+45,376499+79,3b6799+91,3b6799+91,1288e2+100 */
  background: #1e5799; /* Old browsers */
  background: -moz-linear-gradient(45deg, #1e5799 0%, #399ae5 26%, #7db9e8 38%, #4ea3e5 45%, #376499 79%, #3b6799 91%, #3b6799 91%, #1288e2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #1e5799 0%, #399ae5 26%, #7db9e8 38%, #4ea3e5 45%, #376499 79%, #3b6799 91%, #3b6799 91%, #1288e2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #1e5799 0%, #399ae5 26%, #7db9e8 38%, #4ea3e5 45%, #376499 79%, #3b6799 91%, #3b6799 91%, #1288e2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1e5799", endColorstr="#1288e2",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

hr.fading-line {
  display: block;
  border: none;
  color: white;
  height: 2px;
  background: black;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4198e1+0,e2e8f0+87,e2e8f0+100 */
  background: #4198e1; /* Old browsers */
  background: -moz-linear-gradient(left, #4198e1 0%, #e2e8f0 87%, #e2e8f0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #4198e1 0%, #e2e8f0 87%, #e2e8f0 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #4198e1 0%, #e2e8f0 87%, #e2e8f0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4198e1", endColorstr="#e2e8f0",GradientType=1 ); /* IE6-9 */
}